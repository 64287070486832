



































import { BatalhaoService } from "@/core/services/geral";
import { Vue, Component, Prop, Watch} from "vue-property-decorator";

@Component
export default class VeiculoDetail extends Vue {
    @Prop() private value!: string;
    @Prop() private item!: any;

    dialog: boolean = false;

    regioes: any[] = [];
    isRegioesLoading: boolean = false;
    onSearchRegioes: any = null;
    batalhaoService = new BatalhaoService();

    @Watch("value")
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    @Watch("item.latitude")
    @Watch("item.longitude")
    GerarLink(){
        this.item.localizacao = `https://maps.google.com/?q=${this.item.latitude},${this.item.longitude}`;
    }

    @Watch('onSearchRegioes')
    SearchRegioes (val: string) {

        if (this.isRegioesLoading) return;
        if(this.item.localRoubo) return;
        if (!val) return;

        this.isRegioesLoading = true
        this.batalhaoService.Regioes(val)
        .then(
            res => {
                this.regioes = res.data.value;
            },
            err => this.$swal('Aviso', err.response.data, 'error')
        )
        .finally(() => (this.isRegioesLoading = false));
    }

    Salvar(){
        this.Close();
        this.$emit("salvou");
    }

    Close(){
        this.dialog = false;
    }

    beforeUpdate() {
        if(!this.dialog){
            this.$emit("fechou");
        }
    }
}
