

















import { RelatorioRoubadoModel } from '@/core/models/shared';
import { PageBase } from '@/core/services/shared';
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class RoubadoCard extends PageBase { 
    @Prop() private item!: RelatorioRoubadoModel;

    AbrirLink(link: string) {
        window.open(link,'_blank')
    }   
}
