import Vue from 'vue';
import { colors } from 'vuetify/lib';
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/src/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
      locales: { pt },
      current: 'pt',
    },
    theme: {
      dark: false,
      default: 'light',
      themes: { 
        light: {
          primary: '#009bdd',
          secondary: '#4dc9ff'
        },
        dark: {
          primary: '#009bdd',
          secondary: '#4dc9ff'
        }
       },
    }
});
