import {http} from '@/core/ApiConfig';

export class Service{

  private _nomeControle: string = '';

  constructor(nomeControle: string){
      this._nomeControle = nomeControle;
  }

  protected GetHeader(include?: string){
    
    let header = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        "include": ""
      }
    };

    if (include) {
      header.headers.include = include;
    }

    return header;
  }

  public GetNomeControle() : string{
      return this._nomeControle;
  }

  public ListarTudo(sortBy?: string) : Promise<any>{
    let strParameters = this._nomeControle;

    if (sortBy){
      strParameters += `?$orderby=${sortBy}`;
    }

    return http.get(strParameters, this.GetHeader());
  }

  public ListarTudoFiltro(filter:string) : Promise<any>;
  public ListarTudoFiltro(filter:string, sortBy: string) : Promise<any>;  
  public ListarTudoFiltro(filter:string, sortBy: string, include: string) : Promise<any>;  
  public ListarTudoFiltro(filter:string, sortBy?: string, include?: string) : Promise<any> {
    
    let strParameters = `${this._nomeControle}`;

    if (filter){
      strParameters += `?$filter=${filter}`;
    }
    
    if (sortBy){
      strParameters += filter ? '&' : '?';
      strParameters += `$orderby=${sortBy}`;
    }

    return http.get(strParameters, this.GetHeader(include));
  }

  public Salvar(modelo: any) : Promise<any>;
  public Salvar(modelo: any, id: string) : Promise<any>;
  public Salvar(modelo: any, id?: string) : Promise<any> {

    if (id){
      return http.patch(`${this._nomeControle}/${id}`, modelo, this.GetHeader()) 
    }

    return http.post(this._nomeControle, modelo, this.GetHeader());

  }

}