







































import { Component, Watch } from 'vue-property-decorator';
import { ConsultaVeiculo, Veiculo } from '@/core/models/geral';
import { VeiculoService } from '@/core/services/geral';
import { PageBase } from '@/core/services/shared';

@Component
export default class Home extends PageBase {
  navigation = "1";
  loading: boolean = false;
  dialog: boolean = false;
  veiculo: Veiculo = new Veiculo();
  item: ConsultaVeiculo = new ConsultaVeiculo();
  service: VeiculoService = new VeiculoService();
  fieldRules: any[] = [
    (v: any) => !!v || 'Campo obrigatório',
  ];
  mskPlaca = process.env.VUE_APP_MSK_PLACA;
  tipos: any[] = [
    {
      id: 1,
      nome: 'Placa'
    },
    {
      id: 2,
      nome: 'Chassi'
    }
  ];
  chassis: [] = [];
  onSearchChassi: any = null;
  isChassiLoading: boolean = false;

  @Watch('onSearchChassi')
  searchCliente (val: string) {

      if (this.isChassiLoading) return;
      if (!val) return;

      this.isChassiLoading = true
      this.service.ChassiAutoComplete(val)
          .then(
              res => {
                  this.chassis = res.data;
              },
              err => this.$swal('Aviso', err.response.data, 'error')
          )
          .finally(() => (this.isChassiLoading = false));
  }

  Limpar(){
    this.item.placa = '';
    this.item.chassi = '';
  }

  Consultar(){
    this.loading = true;

    this.service.Consulta(this.item)
      .then(res => {
          this.veiculo = new Veiculo(res.data);
          this.dialog = true;
      }, 
      err => {

        if (err.response.status === 404) {

          this.$swal({
            title: 'Atenção!',
            text: 'Veículo não encontrato, deseja cadastra-lo?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            showCloseButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {          
              this.veiculo = new Veiculo();
              this.veiculo.placa = this.item.placa;
              this.dialog = true;
              },
            // @ts-ignore: Unreachable code error
            allowOutsideClick: () => !this.$swal.isLoading()
            })
        }
        else {
          this.$swal('Aviso', err.response.data, err.response.status === 400 ? 'warning' : 'error');
        }

      }
    ).finally(() => this.loading = false);

  }

}
