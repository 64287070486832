import Vue from 'vue'

import MasterPage       from "./shared/MasterPage.vue";
import InformacaoCard   from "./shared/InformacaoCard.vue";

import VeiculoDetail    from "./VeiculoDetail.vue";
import ReportarDialog   from "./ReportarDialog.vue"
import RoubadoCard   from "../components/geral/roubados/RoubadoCard.vue"

Vue.component("master-page", MasterPage);
Vue.component("informacao-card", InformacaoCard);

Vue.component("veiculo-detail", VeiculoDetail);
Vue.component("reportar-dialog", ReportarDialog);
Vue.component("roubado-card", RoubadoCard);