import { http, httpHeader } from '@/core/ApiConfig';
import { Service } from '../shared';

export class BatalhaoService extends Service{

  constructor(){
    super('batalhao');
  }

  public Regioes(q: string) {
    return http.get(`${this.GetNomeControle()}/regioes`, {
      params: {
        q: q
      },
      headers: httpHeader.headers
    });
  }
  
}