










































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Empresa, Veiculo, SituacaoVeiculo } from "@/core/models/geral";
import { EmpresaService, SituacaoVeiculoService, VeiculoService } from "@/core/services/geral";
import jiff from 'jiff';

@Component
export default class VeiculoDetail extends Vue {
  @Prop() private item!: Veiculo;
  @Prop() private value!: boolean;

  dialog = false;
  dialogReportar: boolean = false;
  valid = true;
  service: VeiculoService = new VeiculoService();
  itemOriginal!: Veiculo;
  empresas: Empresa[] = [];
  situacaoVeiculos: SituacaoVeiculo[] = [];
  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
  ultimoHistorico: any = {}
  $refs!: {
    formVeiculo: HTMLFormElement
  }
  reportItem: any = {
    situacaoId: "",
    longitude: "",
    latitude: "",
    localizacao: "",
    localRoubo: ""
  }
  dialogInforme: boolean = false;
  informe = {
    placa: "",
    chassi: "",
    batalhao: "",
    marca: "",
    modelo: "",
    cor: "",
    link: ""
  }

  @Watch('item')
  Item() {
    if (this.$refs.formVeiculo) {
      this.$refs.formVeiculo.resetValidation();
    }

    this.service.UltimoHistorico(this.item.placa, this.item.chassi).then(
      res => {
        this.ultimoHistorico = res.data;
      }
    )

  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
      if (!this.item.id) {
        this.item.situacaoId = this.situacaoVeiculos[0].id;
      }
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  AbrirInforme(){

    this.informe = {
      placa: this.item.placa,
      chassi: this.item.chassi,
      batalhao: this.ultimoHistorico? this.ultimoHistorico.localRoubo? this.ultimoHistorico.localRoubo.complemento : '' : '',
      marca: this.item.marca,
      modelo: this.item.modelo,
      cor: this.item.cor,
      link: this.ultimoHistorico? this.ultimoHistorico?.localizacaoLink : ''
    }

    this.dialogInforme = true;
  }

  mounted() {
    const empresaService = new EmpresaService();
    empresaService.ListarTudo().then(
      res=>{
        this.empresas = res.data.items;
      },
      err=>{
        this.$swal('Aviso', err.message, 'error')
      }
    )

    const situacaoVeiculoService = new SituacaoVeiculoService();
    situacaoVeiculoService.ListarTudo().then(
      res=>{
        this.situacaoVeiculos = res.data.items;
      },
      err=>{
        this.$swal('Aviso', err.message, 'error')
      }
    )
  }

  AbrirMapa(link: string){
    if(link){
      window.open(link, '_blank');
    }
  }

  MudarSituacao(situacaoId: string){
    this.reportItem = {
      situacaoId: situacaoId,
      longitude: "",
      latitude: "",
      localizacao: "",
      localRoubo: ""
    }
    if(this.reportItem.situacaoId != "1215E696-F68B-4CB6-BA08-DDC95BEE39A6"){
      this.dialogReportar = true;
    }
    else{
      this.SalvarMudarSituacao();
    }
  }

  SalvarMudarSituacao(){

    this.service.MudarSituacao(this.item.id, this.reportItem.situacaoId, this.reportItem.latitude, this.reportItem.longitude, this.reportItem.localizacao, this.reportItem.localRoubo).then(
      res => {
        this.$swal('Aviso', res.data, 'info');
        this.Close();
      }
    )
  }

  Salvar(){
    if (this.$refs.formVeiculo.validate()) {
      let pacthModel = jiff.diff(this.itemOriginal,this.item,false);

      (this.item.id ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item))
      .then(
        res => {
          this.$swal("Aviso", "Operação realizada com sucesso!", res.status == 201 || res.status == 200 ? "success" : "warning");
          this.$emit("salvou");
          this.Close();
        },
        err => {
          if (err.response.status == 400) {
            this.$swal("Aviso", err.response.data.title, "warning");
          } else {
            this.$swal("Aviso", err.response.data, "error");
          }
        }
      );
    }
  }

  Close() {
    this.dialog = false;
  }
}
