import { Service } from '../shared';
import {http, httpHeader} from '@/core/ApiConfig';
import { ConsultaVeiculo } from '@/core/models/geral';

export class VeiculoService extends Service{

  constructor(){
    super('veiculo');
  }

  public Consulta(model: ConsultaVeiculo) : Promise<any>{

    return http.get(`${this.GetNomeControle()}/consulta`, {
      headers: this.GetHeader(),
      params: model
    });

  }

  public ChassiAutoComplete(q: string) {
    return http.get(`${this.GetNomeControle()}/ChassiAutoComplete`, {
      params: {
        q: q
      },
      headers: httpHeader.headers
    });
  }

  public UltimoHistorico(placa: string, chassi: string) {
    return http.get(`${this.GetNomeControle()}/UltimoHistorico`, {
      params: {
        placa: placa,
        chassi: chassi
      },
      headers: httpHeader.headers
    });
  }

  public MudarSituacao(id: string, situacaoId: string, latitude: string, longitude: string, localizacao: string, localRoubo: string) {
    return http.post(`${this.GetNomeControle()}/${id}/MudarSituacao/${situacaoId}`, null, {
      params: {
        localizacaoLink: localizacao,
        latitude: latitude,
        longitude: longitude,
        localRouboId: localRoubo
      }
    });
  }
    
}