








































import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class InformacaoCard extends Vue { 
    @Prop() private value!: string;
    @Prop() private item!: any;

    dialog: boolean = false;

    @Watch("value")
    Value() {
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    Copiar(){
        var elm = document.getElementById("conteudo");
        
        if(elm){
            var selection = window.getSelection();
            var range = document.createRange();
            range.selectNodeContents(elm)
            selection?.removeAllRanges();
            selection?.addRange(range);
            document.execCommand("Copy");
            selection?.removeAllRanges();            
            this.$swal("Aviso", "Copiado!", "success");
            this.dialog = false;
        }        
    }

    Fechar(){
        this.dialog = false;
        this.$emit("fechou")
    }   
}
