import { Vue } from "vue-property-decorator";

export class PageBase extends Vue {
    
    public nomeLogo: string ='logo.png'
    public sessionApp: any;
    public app: {empresaNome: string, usuarioId: number, usuarioNome: string, login: string, usuarioFoto: any, empresaLogo: any, temaEscuro: boolean, nomeLogo: string} = {
        empresaNome: '',
        usuarioId: 0,
        usuarioNome: '',
        login: '',
        usuarioFoto: null,
        empresaLogo: null,
        temaEscuro: false,
        nomeLogo: 'logo.png'
    };


    constructor(){
        super();

        this.app.usuarioId = 0;

        if (!localStorage.sessionApp){
            this.$router.push({ name: 'login' });
            return;
        }

        this.sessionApp = JSON.parse(localStorage.sessionApp);

        this.app.empresaNome = this.sessionApp.dados.empresaNome;
        this.app.usuarioId = this.sessionApp.dados.usuarioId;
        this.app.usuarioNome = this.sessionApp.dados.usuarioNome;
        this.app.login = this.sessionApp.dados.login;
        this.app.usuarioFoto = this.sessionApp.dados.usuarioFoto;
        this.app.empresaLogo = this.sessionApp.dados.empresaLogo;
        this.app.temaEscuro = this.sessionApp.dados.temaEscuro;
        this.app.nomeLogo = this.app.temaEscuro ? 'logo-branco.png' : 'logo.png';
    }

    MudarTema(temaEscuro: boolean){
        this.app.temaEscuro = temaEscuro;
        this.sessionApp.dados.temaEscuro = temaEscuro;
        this.app.nomeLogo = this.app.temaEscuro ? 'logo-branco.png' : 'logo.png';
        localStorage.setItem('sessionApp', JSON.stringify(this.sessionApp));
        this.$vuetify.theme.dark = temaEscuro;
    }
}