import { Service } from '../shared';
import {http, httpHeader} from '@/core/ApiConfig';

export class VeiculoHistoricoService extends Service{

  constructor(){
    super('veiculoHistorico');
  }

  public RelatorioROubados(empresaId: string, dataInicial: string, dataFinal: string) {
    return http.get(`${this.GetNomeControle()}/relatorioroubados`, {
      params: {
        empresaId,
        dataInicial,
        dataFinal
      }
    });
  } 
    
}